<template>
  <div>
    <v-card class="ma-1" outlined v-for="(item, index) in items" :key="`i${index}`">
      <v-list-item three-line class="py-0 px-2">
        <v-list-item-content class="pb-0">
          <v-list-item-title class="headline my-0" v-if="item.phone">+{{item.phone}}</v-list-item-title>
          <v-list-item-subtitle>{{item.text}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-card-actions class="py-0 px-2">
        <span class="overline my-0">{{new Date(item.timestamp).toLocaleString()}}</span>
        <v-spacer />
        <v-btn
          icon
          v-if="item.phone"
          :href="`https://wa.me/${item.phone}`"
          target="_blank"
          color="teal accent-4"
        >
          <v-icon>mdi-whatsapp</v-icon>
        </v-btn>
        <v-btn icon @click="removeFromHistoryByIndex(index)" color="red darken-1">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-alert
      type="info"
      color="teal accent-4"
      class="ma-2"
      v-if="items.length == 0"
    >No data available</v-alert>
  </div>
</template>

<script>
export default {
  data: () => ({
    items: (JSON.parse(localStorage.getItem("history")) || []).reverse()
  }),

  methods: {
    removeFromHistoryByIndex: function(index) {
      let items = (JSON.parse(localStorage.getItem("history")) || []).reverse();
      items.splice(index, 1);
      localStorage.setItem("history", JSON.stringify(items.reverse()));
      this.items = items.reverse();
    }
  }
};
</script>
